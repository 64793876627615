<template>
  <div class="cart">
    <CartItems
      @link-clicked="$emit('link-clicked')"
      @cart-recovery="recoverCartByEmail"
      v-model:loading="loading"
    />
    <CartPriceContainer />
    <CartProductRecommendation />
    <CartRecentlyViewedItems
      v-if="cartStore.cart && !cartStore.totalCartItems"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, h, onMounted, ref, watch } from 'vue'
import CartPrice from './CartPrice.vue'
import useDeviceStore from '@/store/device'
import CartItems from './CartItems.vue'
import { useFetchData } from '@/composables/fetchData'
import useCartStore, { fillUpCartAttributes } from '@/store/cart'
import {
  CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
  getCurrentTimeStamp,
  UTMS_ATTRIBUTE_KEY,
  TIME_MARK_CART_START_LOADING,
  EVENT_SAVE_DISCOUNT_CODE_TO_CACHE,
} from '@/utils'
import useDiscountStore from '@/store/discount'
import { CartLineItemUpdateInput } from '@/provider/type'
import Analytics from '@/services/analytics'
import useRouteStore from '@/store/route'
import { saveDiscountCodeToCache } from '@/utils/discount'
import EventBus from '@/services/eventbus'
import useProductStore from '@/store/product'

const CartRecentlyViewedItems = defineAsyncComponent(
  () => import('./CartRecentlyViewedItems.vue')
)
const CartProductRecommendation = defineAsyncComponent(
  () => import('./CartProductRecommendation.vue')
)

const productStore = useProductStore()
const routeStore = useRouteStore()

const props = withDefaults(
  defineProps<{
    wrapper: HTMLElement | null
    isUseWrapper: boolean
    isFullPage: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'link-clicked'): void
}>()

performance.mark(TIME_MARK_CART_START_LOADING)

const newProps = computed(() => {
  return {
    wrapper: props.wrapper,
    isUseWrapper: props.isUseWrapper,
    isFullPage: props.isFullPage,
    itemLoading: loading.value,
    onLinkClicked() {
      emit('link-clicked')
    },
  }
})

const cartTTL = 10 * 60 * 1000 // 10 minutes
const lastTimeFetchCart = ref(getCurrentTimeStamp())
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const { fetchData, loading } = useFetchData({
  fetchDataFunction: fetchCart,
})

onMounted(async () => {
  await fetchData()
  applyDiscount()

  // Update cart attribute after a time out to prevent storefront API rate limit
  setTimeout(() => {
    Analytics.excuteOnReady(async () => {
      if (!cartStore.cart) return
      const currentCartAttributes = cartStore.cart.attributes
      const newCartAttributes = await fillUpCartAttributes(
        currentCartAttributes
      )
      // Only update cart attributes when utms changed or key added
      if (currentCartAttributes?.length === newCartAttributes.length) {
        const currentUtms = currentCartAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        const newUtms = newCartAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        if (currentUtms?.value === newUtms?.value) return
      }

      cartStore.updateCartAttributes({
        cartId: cartStore.cart?.id,
        input: {
          attributes: newCartAttributes,
        },
      })
    })
  }, 200)

  // recover cart when user visit site from klaviyo and has cartId
  watch(
    () => cartStore.shouldRecoveryCartIdBaseOnEmailKlaviyo,
    async () => {
      if (cartStore.shouldRecoveryCartIdBaseOnEmailKlaviyo) {
        cartStore.cart = null
        await fetchData()
      }
    }
  )
  watch(
    () => [
      cartStore.totalCartItems,
      productStore.productRecommendation?.moreItems?.length,
      productStore.productRecommendationShopify?.length,
    ],
    async ([value, productRecommendation, productRecommendationShopify]) => {
      const priceContainer = document.querySelector('.cart__price-container')
      if (!priceContainer) return
      if (value && !productRecommendation && !productRecommendationShopify) {
        priceContainer.classList.remove('cart__price-container-no-sticky')
      } else if (
        !value ||
        productRecommendation ||
        productRecommendationShopify
      ) {
        priceContainer.classList.add('cart__price-container-no-sticky')
      }
    },
    {
      immediate: true,
    }
  )
})

async function recoverCartByEmail() {
  cartStore.cart = null
  await fetchData()
}

async function fetchCart() {
  let cart = await cartStore.getCurrentCart()
  if (!isCheckCartValid(cart)) {
    cart = await cartStore.createCart({
      lines: [],
    })
  }
  cartStore.saveCartAndLines(cart)
}

function isCheckCartValid(cart: any) {
  if (!cart || cart.completedAt) {
    return false
  }
  if (cart.lines) {
    for (let i = 0; i < cart.lines.length; i++) {
      const lineItem = cart.lines[i]
      if (!lineItem.merchandise) {
        return false
      }
    }
  }
  return true
}

function checkRefreshCart() {
  const currentTimeStamp = getCurrentTimeStamp()
  if (currentTimeStamp - lastTimeFetchCart.value > cartTTL) {
    fetchData()
  }
}

async function applyDiscount() {
  if (!cartStore.cart) return

  // check for update discount campaign
  const lineItemsToUpdate: CartLineItemUpdateInput[] = []
  cartStore.localCartLineItems.forEach((lineItem) => {
    const attributes = [...lineItem.attributes].map((elm) => {
      return {
        key: elm.key,
        value: elm.value,
      }
    })
    const campaignAttributeIndex = attributes.findIndex((attribute) => {
      return attribute.key === CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY
    })
    let campaignAttribute = attributes[campaignAttributeIndex]

    // remove the campaign attribute in case can not find any campaign discount code
    if (!discountStore.campaignDiscountCode) {
      if (campaignAttributeIndex == -1) return
      attributes.splice(campaignAttributeIndex, 1)
    }

    // Update the existing or add campaign attribute in case find campaign discount code on the client
    if (campaignAttribute) {
      if (campaignAttribute.value === discountStore.campaignDiscountCode) {
        return
      }
      campaignAttribute.value = discountStore.campaignDiscountCode!
    } else {
      campaignAttribute = {
        key: CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
        value: discountStore.campaignDiscountCode!,
      }
      attributes.push(campaignAttribute)
    }

    // push the lineitem to to update items
    lineItemsToUpdate.push({
      id: lineItem.id,
      attributes,
    })
  })

  if (lineItemsToUpdate.length > 0) {
    const timeStamp = getCurrentTimeStamp()
    cartStore.updateCartLines({
      lines: lineItemsToUpdate,
      timeStamp,
    })
  }

  // check for update discount code
  if (discountStore.discountCode) {
    if (!cartStore.discountCodeCurrentApply) {
      const respone = await cartStore.cartDiscountCodeApply(
        discountStore.discountCode
      )
      if (respone?.userErrors?.length && respone.userErrors[0].message) {
        // remove recently discount code
        const isRemove = true
        const domain = `.${routeStore.currentDomain}`
        await saveDiscountCodeToCache(
          discountStore.discountCode,
          domain,
          isRemove
        )
        return
      }
    }
    const domain = `.${routeStore.currentDomain}`
    await saveDiscountCodeToCache(discountStore.discountCode, domain)
    EventBus.trigger(EVENT_SAVE_DISCOUNT_CODE_TO_CACHE)
  }
}

defineExpose({
  checkRefreshCart,
})

const deviceStore = useDeviceStore()
const CartPriceContainer = () => {
  if (!deviceStore.isMobile && props.isFullPage) {
    return h(
      'div',
      { class: 'cart__price-container' },
      h(CartPrice, newProps.value)
    )
  }
  return h(CartPrice, newProps.value)
}
</script>

<style lang="scss">
.cart {
  $S: &;

  @include media-desktop {
    &--full-page {
      display: grid;
      grid-template-columns: 5fr minmax(300px, 2fr);
      grid-template-areas:
        'a b'
        'c c'
        'd d';
      gap: 2em;

      > .divider {
        display: none;
      }
    }
  }
}
.cart__price-container-no-sticky {
  position: unset !important;
}
</style>
